<template>
  <div class="page-layout detail-page details" >
    
    <HeaderIntroImage backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container" v-if="!articleData">
      <div class="step">
        <br>
        <h3>Loading Data ...</h3>
        <br>
      </div>
    </div>
      
    <div v-if="articleData" class="container">
      <div class="step">
        <p :class="['detail--tag', 'blue', 'pct', 'icon-left', 'target'+getTargetCode(level.attributes.name)]"
          v-for="(level, ind) in articleData.field_level" :key="'level'+ind">{{ level.attributes.name }}
        </p>

        <h2 class="title">{{ articleData.attributes.title }}</h2>

        <div class="info-grid">
          <div class="cell">
            <p class="label">FORMAT</p>
            <p class="blue">{{ format }}</p>
          </div>
          <div class="cell">
            <p class="label">FOCUS</p>
            <p class="blue">{{ assessment }}</p>
          </div>
          <div class="cell">
            <p class="label">DURATION</p>
            <p class="bold blue">{{ articleData.attributes.field_duration }}</p>
          </div>
        </div>

        <div class="content">
          <p v-if="articleData.attributes.field_description" 
            v-html="articleData.attributes.field_description.value"></p>

          <p v-if="articleData.attributes.field_focus_topic" class="label-title">FOCUS TOPIC</p>
          <p v-if="articleData.attributes.field_focus_topic" 
            v-html="articleData.attributes.field_focus_topic.value"></p>

          <p v-if="articleData.attributes.field_research_validation" class="label-title">RESEARCH VALIDATION</p>
          <p v-if="articleData.attributes.field_research_validation" 
            v-html="articleData.attributes.field_research_validation.value"></p>

          <p v-if="articleData.attributes.field_references.length" class="label-title">REFERENCES</p>
          <br>
          <div v-if="articleData.attributes.field_references.length">
            <ul class="list-2">
              <li v-for="(r, ind) in articleData.attributes.field_references" :key="'reference'+ind" 
                v-html="r.value"></li>
            </ul>
          </div>
          
        </div>

        <div class="attachments-grid">
          <div class="box-row" v-if="this.articleData.attributes.field_evaluation_target && this.articleData.attributes.field_evaluation_target.length">
            <div class="box">
              <p class="label">TARGETS</p>
              <p v-for="(t, ind) in this.articleData.attributes.field_evaluation_target" :key="'target'+ind" class="detail--tag">
                <span v-if="t!='Other (specify)'"
                  class="blue bold icon-left target">{{ t }}</span>
              </p>
            </div>

            <div class="box links">
              <p v-if="articleData.attributes.field_url" class="label">LINKS</p>
              <a v-if="articleData.attributes.field_url" 
                :href="articleData.attributes.field_url.uri" 
                target="_blank" class="btn plain slim border">
                <span class="icon-right external">Go to resource</span>
              </a>
            </div>
          </div>

          <div class="people-row" v-if="articleData.attributes.field_author.length">
            <p class="label">AUTHORS</p>
              <ArticlesGrid class="slim">
                <MemberCardMinExternalEmail v-for="(a, ind) in articleData.attributes.field_author" :key="'author_'+ind" 
                  :memberData="a" :name="a.first" :email="a.second" />
              </ArticlesGrid>
          </div>
        </div>
          
      </div>
    </div>

  </div>
</template>

<script>
import HeaderIntroImage from '../../components/layout-blocks/HeaderIntroImage.vue'
import ArticlesGrid from '../../components/layout-blocks/ArticlesGrid.vue'
import MemberCardMinExternalEmail from '../../components/layout-blocks/MemberCardMinExternalEmail.vue'
import { fetchNodes } from '../../libs/drupalClient'
export default {
  name: 'evaluation-tool',
  components: {
    HeaderIntroImage,
    MemberCardMinExternalEmail,
    ArticlesGrid
  },
  props: {},
  data: () => {
    return {
      pageData: {
        attributes: {
          title: "",
          subtitle: ""
        }
      },
      articleData: null
    }
  },
  computed: {
    assessment() {
      switch(this.articleData.attributes.field_assessment_focus) {
        case '1':
          return 'Content knowledge';
        case '2': 
          return 'Problem-solving';
        case '3': 
          return 'Scientific reasoning';
        case '4': 
          return 'Lab skills';
        case '5': 
          return 'Beliefs';
        case '6':
          return 'Attitudes';
        case '7': 
          return 'Interactive teaching';
        default: 
          return 'Evaluation';
      }
    },
    format() {
      switch(this.articleData.attributes.field_format) {
        case '1':
          return 'Multiple-choice';
        case '2': 
          return 'Short answers';
        default:
          return 'Tools';
      }
    },
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    routesData() {
      return this.$store.state.routesData
    },
    objecttitle() {
      return this.$route.params.title
    },
    objectnid() {
      var link = this.routesData.filter(r => r.url_check == this.objecttitle)

      if(this.routesData.length<=0) {
        return ''
      } else {
        if (link.length > 0)
          return link[0].nid
        else 
          return '/not-found'
      }
    },
    organization() {
      if(this.articleData.field_program_organization)
        return this.articleData.field_program_organization[0].attributes
      else return {}
    },
    categoryData() {
      var cd = {
        nid: 0,
        name: ""
      }

      switch(this.articleData.field_level[0].attributes.name) {
        case 'Primary and Secondary School':
          cd.name = "Primary and Secondary School"
          cd.nid = 14
          break;
        case 'Higher education':
          cd.name = "Higher Education"
          cd.nid = 15
          break;
        case 'Lifelong learning and (re)training':
          cd.name = "Lifelong Learning and (re)training"
          cd.nid = 16
          break;
        case 'Outreach':
          cd.name = "Outreach"
          cd.nid = 17
          break;
        default:
          cd.name = ""
          cd.nid = 0
          break;
      }

      return cd
    }
  },
  methods: {
    getTargetCode(name) {
      switch (name) {
        case 'Primary and Secondary School':
          return 14;
        case 'Higher education':
          return 15;
        case 'Lifelong learning and (re)training':
          return 16;
        case 'Outreach':
          return 17;
        default:
          return 0;
      }
    },
    async findCT() {
      if(this.objectnid!='' && this.objectnid!='/not-found') {
        await fetchNodes('evaluation', {
          include: ['field_level'],
          filters: [{
            key: 'drupal_internal__nid',
            value: this.objectnid
          }]
        })
        .then(res => {
          this.articleData = res[0]
          //console.log('evaluation', this.articleData)
        })

      } else if(this.objectnid=='/not-found')
        this.$router.push(this.objectnid)
    }
  },
  async mounted() {
    await this.findCT()
  },
  watch: {
    async objectnid() {
      await this.findCT()
    }
  }
}
</script>

<style lang="scss" scoped>
.author {
  margin: 1em 0;
  p { margin: .5em 0; }
}
</style>
